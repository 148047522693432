/***********************************************/
// Capa de Servicios para el modulo de Ciudades.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/blogcategories");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/blogcategories/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    addRecord(formData) {
        // console.log('Adding Record.', formData);
        let result = null;
        axios.post(this.#baseUrl + "/blogcategories", formData)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.log('Error: ', error);
                    result = this.handleError(error);
                }
            );
        return result;
    }

    // Pushes posts to the server when called.
    updateRecord(id, recordData) {
        let result = null;

        let formData = new FormData();
        formData.append("id", recordData.id);
        formData.append("name", recordData.name);
        // console.log('Updating Record: ', formData);
        axios.post(this.#baseUrl + '/update-record/blogcategories', formData)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.error('Error: ', error);
                    result = error;
                }
            );
        return result;
    }

    // Pushes posts to the server when called.
    /*updateRecord(formData) {
        let result = null;
        console.log('Actualizando Record: ', formData);
        axios.put(`${this.#baseUrl}/blogcategories/${formData.id}`, formData)
            .then(response => {
                console.log('Respuesta: ', response);
                result = response.data;
            })
            /!* handle error *!/
            .catch(error => {
                    // console.log('Error: ', error);
                    result = this.handleError(error);
                }
            );
        return result;
    }*/

    deleteRecord(id) {
        let result = null;
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/blogcategories/${id}`)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.log('Error: ', error);
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error; // error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }
}