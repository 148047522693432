<template>
    <blogs-categories-component/>
</template>

<script>
    import BlogsCategoriesComponent from "@/components/admin/catalogs/BlogsCategoriesComponent";
    
    export default {
        name: "BlogsCategories",
        title: "Categorías Blogs | Turismo BC",
        components: { BlogsCategoriesComponent },
    }
</script>

<style scoped>

</style>